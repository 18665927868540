@font-face {
  font-family: 'ABCMaxiRoundMono-Regular';
  src:  url('Fonts/ABCMaxiRoundMono-Regular.woff2') format('woff2'),
        url('Fonts/ABCMaxiRoundMono-Regular.woff') format('woff');
}
@font-face {
  font-family: 'ABCMonumentGroteskMono-Thin';
  src:  url('Fonts/ABCMonumentGroteskMono-Thin.woff2') format('woff2'),
        url('Fonts/ABCMonumentGroteskMono-Thin.woff') format('woff');
}
@font-face {
  font-family: 'ABCMonumentGroteskMono-Regular';
  src:  url('Fonts/ABCMonumentGroteskMono-Regular.woff2') format('woff2'),
        url('Fonts/ABCMonumentGroteskMono-Regular.woff') format('woff');
}
@font-face {
  font-family: 'ABCMonumentGroteskMono-Medium';
  src:  url('Fonts/ABCMonumentGroteskMono-Medium.woff2') format('woff2'),
        url('Fonts/ABCMonumentGroteskMono-Medium.woff') format('woff');
}
@font-face {
  font-family: 'ABCMonumentGroteskMono-Light';
  src:  url('Fonts/ABCMonumentGroteskMono-Light.woff2') format('woff2'),
        url('Fonts/ABCMonumentGroteskMono-Light.woff') format('woff');
}

.c-black{ color: #000000; } 
.c-blue{ color: #0127F5; }
.c-green{ color: #43FF5F;}
.c-grey{ color: #CDCED2; }
.c-pink{ color: #FF38B9; }
.c-purple{ color: #6A42C2; }
.c-red{ color: #F15148; }
.c-yellow{ color: #FDEF38; } 


.bg-black{ background: #000000; } 
.bg-blue{ background: #0127F5; }
.bg-green{ background: #43FF5F;}
.bg-grey{ background: #CDCED2; }
.bg-pink{ background: #FF38B9; }
.bg-purple{ background: #6A42C2; }
.bg-red{ background: #F15148; }
.bg-yellow{ background: #FDEF38; }

.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}

.App {
  width: 100vw;
  height: 100vh;
  background:#fff;
  /* background: #212121; */
  /* background: linear-gradient(0deg, rgb(14, 166, 125) 0%, rgb(224, 158, 53) 100%); */
  color: #000;
  font-size:1rem;
  display: grid;
  place-items: center;
  transition: 2000ms ease background;
}
.App.login.t-0{ background: #fff; color:#000; }
.App.login.t-1{ background: #CDCED2; color:#000; }
.App.login.t-2{ background: #0127F5; color:#fff; }
.App.login.t-3{ background: #F5588F; color:#fff; }
.App.login.t-4{ background: #6A42C1; color:#fff; }

.App.login.t-0 input, .App.login.t-0 select,
.App.login.t-1 input, .App.login.t-1 select{
  border-color:#000;
  color:#000;
  outline: 0;
}
.App.login.t-2 input, .App.login.t-2 select,
.App.login.t-3 input, .App.login.t-3 select,
.App.login.t-4 input, .App.login.t-4 select{
  border-color:#fff;
  color:#fff;
  outline: 0;
}
.App.login.t-0 input::placeholder,
.App.login.t-1 input::placeholder{
  color:#000;
}
.App.login.t-2 input::placeholder,
.App.login.t-3 input::placeholder,
.App.login.t-4 input::placeholder{
  color:#fff;
}

.App.login .kp-code{
  text-transform: none;
}

.App.login.t-0 button{ background: #43FF5F; color:#000; }
.App.login.t-1 button{ background: #0127F5; color:#fff; }
.App.login.t-2 button{ background: #F5588F; color:#fff; }
.App.login.t-3 button{ background: #5DD9C1; color:#fff; }
.App.login.t-4 button{ background: #FDEF38; color:#000; }

.App.login button:disabled{
  opacity: 0.25;
}

.App.login input,
.App.login select,
.App.login input[type="checkbox"]{
  border-width:2px;
  border-style: solid;
  background: transparent;
  /* border-radius: 2rem; */
  font-size:1.25rem;
  padding:0 1rem;
  width: 100%;
  box-sizing: border-box;
  line-height: 3rem;
  height:3rem;
  margin-bottom:1rem;
  text-transform: uppercase;
}
.App.login button{
  /* border-radius: 2rem; */
  font-size:1.25rem;
  padding:0 1rem;
  width: 100%;
  transition: 150ms cubic-bezier(0.39, 0.575, 0.565, 1) all;
  border: 0;
  line-height: 3rem;
  height:3rem;
  cursor: pointer;
}

.App.login>div{
  display: grid;
  justify-content: center;
  align-items: center;
}

.App.login h1{
  font-family: 'ABCMonumentGroteskMono-Regular', monospace;
  text-transform: uppercase;
  /* color:#43FF5F; */
  /* font-size:5vw; */
  /* width:100vw; */
  align-self: center;
  text-align: center;
  margin:0 0 4rem 0;
  white-space: pre;
  user-select: none;
  font-size:1.25rem;
}

#kp-title{
  width: fit-content;
}
.kp-join-panel{ 
  align-self: center; 
  margin:0 auto;
  /* padding:1px; */
}

.kp-colorpicker{
  display: flex;
  justify-content: space-between;
  gap:1rem;
  height:4rem;
  margin-bottom:1rem;
}
.kp-colorpicker>div{
  width: 4rem;
  border-radius: 2rem;
}
.kp-colorpicker>div.active{
  box-shadow: 0 0 1rem 2px rgba(255, 255, 255, 0.5);
}

.App.logged-in{
  /* background: #CDCED2; */
  /* color:#000; */
}
body {
  margin: 0%;
  padding: 0%;
  /* cursor: crosshair; */
  overflow: hidden;
}
body,input, button, select{
  font-family: 'ABCMonumentGroteskMono-Regular', monospace;
}
button{
  text-transform: uppercase;
}
strong{
  font-family: 'ABCMonumentGroteskMono-Medium', monospace;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
select{
  /* width:100%; */
  height:100%;
}
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700"); */

#kp-window{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition:1000ms ease background;
}

#kp-gui{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  /* background:rgba(200,0,0,0.25); */
  /* color: red; */
  display: grid;
  /* grid-template-columns: [leftcol] 200px [midcol] auto [rightcol] 200px [end];
  grid-template-rows: [toprow] 25% [midrow] auto [bottomrow] 150px; */
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 4.375rem 1fr 4.375rem;
  grid-template-areas: 
  "tl tm tr"
  "ml mm mr"
  "bl bm br"; 
  pointer-events: none;
  box-sizing: border-box;
  
}
.r-guest #kp-gui{
  text-emphasis: none;
  /* grid-template-rows: 1fr 1fr 1fr 4.375rem; */
}

#kp-gui .kp-info{
  position: relative;
}

#kp-gui .kp-info,
#kp-gui .kp-users{
  pointer-events: all;
}
#kp-gui .kp-users p{
  margin:0;
}
#kp-gui .kp-users p.me{
  font-family: 'ABCMonumentGroteskMono-Medium';
}

p.me:after{
  content: '(me)';
}

.kp-panel .btn-group{
 display: flex;
 gap: 1rem;
}

.kp-panel button{
  display: flex;
  box-sizing: border-box;
}
.kp-panel button,
.kp-panel input,
.kp-panel select{
  padding:0 1.25rem;
  background: transparent;
  font-size:1rem;
  text-transform: uppercase;
  height:2.25rem;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0;
  cursor: pointer;
} 
.panel-functions input.kp-qfield{
  text-transform: none;
}
.kp-panel-body button{
  display: initial;
}
button.bg-green{
  background:#43FF5F !important;
  color:#000 !important;
}
button.bg-red{
  background:#F15148 !important;
}

.btn-rd{
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  text-align: center;
  justify-content: center;
}
.btn-rd.active{
  background:#fff;
}
.btn-rd.active svg{
    fill:#000;
}
.btn-rd.mr-1{
  margin-right:1rem;
}

.kp-panel button svg, .btn-rd svg{
  align-self: center;
  width:1rem;
  height:1rem;
}

.kp-info span{
  /* background: rgba(0,0,0,0.85); */
  width:fit-content;
  /* color:#000; */
  border:1px solid #fff;
  padding:.125rem;
  display: inline-block;
  transition: 300ms transform cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor:pointer
}
.kp-info:hover span{
  background: rgba(0,0,0,0.9);
  color:#fff;
  transform: rotateZ(-3deg);
}

.checkbox-box{
  display: none;

}

.kp-gui-content{
  position: relative;
  z-index: 999;
}

h2{
  overflow-wrap: break-word;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 16px;
}


.yt-player{
  height: 0; 
  padding-bottom: 56.25%; 
}

.kp-info.kp-panel{
  text-align: center;
  grid-area: tm;
  line-height: 1rem;
  height:fit-content;
  color:rgba(0,0,0,.5);
}

.kp-info:hover{
  /* transform:translateY(0); */
  transform:scale(1.02);
}

.log-window {
  /* width: 100vw; */
  height: 100vh;
  /* padding:1rem; */
  box-sizing: border-box;
  overflow: hidden;
  /* padding-bottom:4.375rem; */
}

.log-window p {
  margin: 0;
}

.kp-call{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height:100vh;
  pointer-events: none;
  display: flex;
  justify-content: center;
  /* visibility: hidden; */
}

.kp-call-box{
  width:100vw;
  height:100vh;
  /* height:4.375rem; */
  /* background: rgba(255,255,255,0.5); */
  align-self: center;
}
.remote-player-wrapper.plus-video{
  background:rgba(0,0,0,0.5);
  /* backdrop-filter: blur(5px); */
  position:fixed;
  top:0; left:0;
  width:100vw;
  height:100vh;
  pointer-events: none;
  display: flex;
  justify-content: center;
}
.remote-player-wrapper .video-player{
  align-self: center;
  width:80vw;
  height:45vw;
}

#kp-head{
  /* background:transparent; */
  padding: 0 1.5rem 0 1.5rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom-width:1px;
  border-bottom-style: solid;
  position:fixed;
  width:100vw;
  height:4.375rem;
  top:0;
  transition: 1000ms ease background;
  user-select: none;
}
#kp-head button{
  pointer-events: all;
}
#kp-head h1{
  margin:0;
  font-size:1.5rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 4.375rem;
  white-space: nowrap;
}
#kp-head .kp-user{
  /* text-align: right; */
  display: flex;
  align-items: center;
  gap: .5rem;
  justify-content: flex-end;
  pointer-events: all;
  cursor: pointer;
  user-select: none;
}
#kp-head .kp-user:hover{
 /* text-decoration: underline; */
}

.kp-header {
  padding:1rem;
  display: flex;
  margin-bottom: .5em;
  /* flex-flow: wrap; */
  
}
.kp-header>div{
  align-self: flex-start;
  display: flex;
  backdrop-filter: blur(5px);
  border-radius: 1rem;
  column-gap: .5rem;
}

.kp-header .user-meta{
  align-self: center;
}

.log-window .chat-body {
  /* height: calc(70vh - (45px + 70px)); */
  /* border: 1px solid #263238; */
  /* background: #fff; */
  height:100%;
  position: relative;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  overflow: hidden;
  /* padding-top:4.375rem; */
}
.transcript{
  color: #5243a0;
}

.log-window .chat-body .message-container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  grid-column: span 3;
  line-height:1.5em;
  word-wrap:break-word;
  width: 100%;
  height: calc(100vh - 10rem) !important;
  margin:0 auto;
  counter-reset: line;
  padding-top:1.5rem;
  transform: translateY(4.375rem);
}
.message-container{
  font-size:1.5vw;
}

.message-container .grp{
  display: grid;
  grid-template-columns: [start] 7.5vw [content] auto;
}

.grp-prt.question .g-subline{
  display: none;
}

.grp-prt.headline{
  background:#000;
  color:#fff;
}

.grp-prt.headline strong,
.grp-prt.headline .g-subline,
.grp-prt.headline .avatar{
  display: none;
}
.grp-prt.headline .g-line{
  font-size: 3vw;
}
.grp-prt.headline .g-line:before{
  font-size: 1.5vw;
}


.message-container .g-n{ 
  align-self:flex-end; 
  margin-bottom:-.2rem;
}
.message-container .g-l{ 
  align-self:flex-end; 
  text-align: right; 
  padding-right: 20px;
  color:rgba(0,0,0,0.3);
  pointer-events: none;
  overflow-y: hidden;
  height:0;
}

.message-container .g-c{ 
  align-self:flex-end;
}
.message-container .g-c>div{
  position: relative;
}
.message-container .headline.odd .g-line:before,
.message-container .headline .g-line:before{
  width:2vw;
  transform: translateX(-2vw);
}
.message-container .headline.odd .g-c{
  padding-left:inherit
}

.message-container .odd .g-c,
.message-container .odd.f2x .g-c{
  padding-left:4vw;
}
.message-container .odd .g-line:before,
.message-container .odd.f2x .g-line:before {
  width:4vw;
  transform:translateX(-8vw);
  /* background:indianred; */
}

.message-container .g-line,
.message-container .g-subline{
white-space:pre;
}

.message-container .g-line{
  transition:300ms cubic-bezier(0.33, 1, 0.68, 1) all;
  white-space: nowrap;
  width: fit-content;
  user-select: none;
}
.g-subline{
  color:#43FF5F;
  pointer-events: none;
  transform:translateY(-1.5em);
  height:0;
  transition:1000ms color ease;
}

.message-container .g-subline:before {
  content: '';
  display: inline-block;
  /* border-right: 1px solid #ddd; */
  /* padding: 0 .5em; */
  /* margin-right: -3rem; */
  width:4vw;
  text-align: right;
  transform:translateX(-4vw);
  color: #888
}

.g-line b{
  border-bottom: 20px solid rgb(227, 180, 26);
  font-weight: normal;
}
.g-line u{
  text-decoration: none;
  /* border-bottom:10px solid rgb(224, 158, 53); */
  /* background:rgba(255,255,255,0.2); */
  /* animation:both infinite cubic-bezier(0.83, 0, 0.17, 1) 3s wiggle; */
  font-family: 'ABCMaxiRoundMono-Regular';
  text-transform: uppercase;
  display: inline-block;
  /* background-color: #43FF5F; */
}
.g-line strong:after{
  content: ' ';
}
.g-subline strong:after{
  content: ' ';
}

.transcript-overlay{
  pointer-events: none;
  color:rgba(0,0,0,0);
  position: absolute;
  top:2rem;
}


.message-container .g-line:hover strong{
  font-style: normal;
}
.message-container .g-line:before {
  counter-increment: line;
  content: counter(line, decimal-leading-zero);
  display: inline-block;
  width:4vw;
  text-align: right;
  transform:translateX(-4vw);
  /* opacity: 0.4; */
  color:rgba(0,0,0,0.25);
  font-style: normal;
  /* background:orange; */
  animation: scaleno 300ms cubic-bezier(0.5, 0.025, 0.475, 0.995);
  animation-fill-mode: forwards;
  transform-origin: 80% 50%;
}

@keyframes scaleno {
  0% {
    /* transform: translateX(-4vw) scale(0.6); */
    opacity: 0;
  }
  100% {
    opacity: 1;
    /* transform: translateX(-4vw) scale(1); */
    
  }
}


/* .message-container .g-line:after { 
  content: attr(data-react);
  display: inline-block;
  width:fit-content;
  text-align: right;
  transform:translateX(1rem);
  padding:0 .5rem;
  color: #fff;
  background:#000;
}

.message-container .g-line:hover:after { 
  content: '<';
  display: inline-block;
  width:fit-content;
  text-align: right;
  transform:translateX(1rem);
  padding-right:1rem;
} */

.g-line[data-react="1"]{ background:rgba(0, 204, 139, 0.1); }
.g-line[data-react="2"]{ background:rgba(0, 204, 139, 0.2); }
.g-line[data-react="3"]{ background:rgba(0, 204, 139, 0.3); }
.g-line[data-react="4"]{ background:rgba(0, 204, 139, 0.4); }
.g-line[data-react="5"]{ background:rgba(0, 204, 139, 0.5); }
.g-line[data-react="6"]{ background:rgba(0, 204, 139, 0.6); }
.g-line[data-react="7"]{ background:rgba(0, 204, 139, 0.7); }
.g-line[data-react="8"]{ background:rgba(0, 204, 139, 0.8); }
.g-line[data-react="9"]{ background:rgba(0, 204, 139, 0.9); }
.g-line[data-react="10"]{ background:rgba(0, 204, 139, 1); }


.message-container .g-r{ 
  align-self:flex-end; 
}

/* .log-window .chat-body .message-container::-webkit-scrollbar { */
  /* display: none; */
/* } */
.log-window .chat-body .message {
  height: auto;
  display: flex;
}
.log-window .chat-body .message.temp{
  opacity: .2;
}

.bubble-sm{
  background: #FF38B9;
  color:#fff;
  height:1rem;
  width:fit-content;
  padding:0 .25rem;
  box-sizing: border-box;
  margin-right:-.5rem;
  line-height: 1rem;
  border-radius:.5rem;
  text-align: center;
  transform:scale(0) translateX(-.5rem);
  transition:300ms cubic-bezier(0.075, 0.82, 0.165, 1) all;
}
.bubble-sm.visible{
  transform:scale(1) translateX(-0.5rem);
}

.bubble{
  background:#FF38B9;
  color:#fff;
  min-width:1.5em;
  /* display: inline-block; */
  height:1.5em;
  text-align: center;
  line-height: 1.5em;
  padding:0 .5em;
  opacity: 0;
  transition:300ms cubic-bezier(0.33, 1, 0.68, 1) all;
  margin: -0.25rem 1rem;
}
.bubble.visible{
  opacity: 1;
}

.kp-questions{
  transform:translateX(-100%);
  color:#111;
  grid-area: ml;
  pointer-events: all;
  min-height: 50vh;
  max-height: 70vh;
  overflow-y:scroll;
  overflow-x: hidden;
}
.kp-questions.visible{
  transform:translateX(0);
}
/* .kp-questions:hover{
  transform: translateX(0);
} */

.kp-question-body{
  /* background:#fff; */
  /* padding:.5rem; */
  transition:300ms cubic-bezier(0.33, 1, 0.68, 1) all;
  cursor: pointer;
  margin-bottom: .5em;
  font-size:1rem;
  border-top:1px solid #000;
  padding-top:1rem;
}
.kp-question-body>div{
  display:flex;
  gap: 1rem;
}

.kp-question-body span{
  line-height: 2rem;
}

.kp-question-body strong{
  margin-right: 1rem;
}

.kp-question-body p{
  margin-top:0;
}

.kp-question-status-1{
  color:red;
}
.kp-question-status-2{
  color:#ccc;
}

.wip{
  opacity: .5;
}

.kp-panel-head{
  border-bottom:1px solid #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height:3.25rem;
}
.kp-panel-head>div{
  padding:1rem 2rem;
  text-transform: uppercase;
}
.kp-panel-head>button{
  align-self: center;
  margin:-0.5rem 0;
}
.btn-x{
  text-align: right;
}
.btn-x svg{
  transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1) all;
}
.btn-x:hover svg{
  transform:scale(2);
}
.btn-x svg{
  fill:#000;
}
.kp-panel-body{
  padding:2rem;
}
.kp-panel-body hr{
  margin:1rem -2rem;
}

.panel-schedule{
  /* padding:1rem; */
  overflow-x: hidden;
}
.panel-schedule table{
  border-spacing: 0;
}
.panel-schedule table th{
  font-family: 'ABCMonumentGroteskMono-Medium', sans-serif;
  text-align: left;
}

.panel-schedule table tr:hover{
  background:#5243a0;
  color:#fff;
}


.panel-schedule table th,
.panel-schedule table td{
  padding:.5rem 0.5rem;
  vertical-align: top;
  border:0;
  /* border-bottom: 1px dotted #ccc; */
}
.panel-schedule table th:first-child,
.panel-schedule table td:first-child{
  padding-left:4rem;
}
.panel-schedule table td:first-child{
padding:1.25rem 0.5rem 1rem 4rem;
}
.panel-schedule table td.td-title{
  font-size:1.25rem;
}
.panel-schedule table td strong{
  display: block;
}

.kp-panel-images{
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap:1rem;

  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: masonry; */
}
.kp-panel-images img{
  /* width:100%; */
  /* height:100%; */
}
.kp-panel-images div{
  transition: 300ms ease all;
}
.kp-panel-images div.active{
  box-shadow: 0 0 1rem .25rem rgba(161, 255, 210, 1);
}
.kp-settings img,
.settings-true .kp-settings img{
  opacity: 1;
  border-radius: 0;
}

.kp-settings{
  /* grid-area: mm; */
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start:2;
  grid-row-end:4;

  opacity: 0;
  transform: perspective(75em) rotateX(8deg) scale(0.97);
  transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1) all;
  pointer-events: none;
  overflow-y:scroll;
}
.kp-settings.visible{
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.btn-inline{
  display: inline-block;
}

.kp-users{
  grid-area: mr;
  transform:translateX(100%);  
  min-height: 50vh;
  max-height: 70vh;
  overflow-y: scroll;
}
.kp-users.visible{
  /* backdrop-filter: blur(5px); */
  transform:translateX(0);
}

.kp-panel{
  transition:300ms cubic-bezier(0.33, 1, 0.68, 1) all;
  background:#777;
}

.kp-voice-recognition{
  grid-area: mm;
  pointer-events: none;
}

.hidden{
  visibility: hidden;
  width:0;
  height:0;
  overflow: hidden;
}
.hide {
  opacity: 0 !important;
}

.kp-btn-play-big{
  
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height:100vh;
  pointer-events: none;
  display: flex;
  justify-content: center;
  pointer-events: none;
  background:rgba(0,0,0,0);
}

.kp-btn-play-big.playing button{
  transform: scale(0.9);
  opacity: 0;
}
.kp-btn-play-big>button{
  /* position: relative; */
  background: #ccc;
  /* mix-blend-mode: exclusion; */
  /* backdrop-filter: blur(1em); */
  /* backdrop-filter: blur(5px); */
    pointer-events: all;
  font-size:4vw;
  border:0.05em solid #fff;
  color:#fff;
  padding:0 1.5em;
  cursor: pointer;
  transition: 300ms cubic-bezier(0.39, 0.575, 0.565, 1) all;
  opacity: 1;
  align-self: center;
  /* align-self: */
}
.kp-btn-play-big>button:hover{
  outline:0.15em solid #fff;
  /* letter-spacing: .1em;
  padding:0 1.2em; */
}

.panel-functions{
  display: flex;
  grid-area: 4/1/4/4;
  gap: 0rem 1rem; 
  pointer-events: all;
  height:4.375rem;
  justify-content: space-between;
  padding:0 1rem;
  align-self: end;
  background: transparent!important;
  align-items: center;
  /* background:#000;
  color:#fff;
  mix-blend-mode: color-burn; */
}

.panel-functions.sharing::before{
  content:'you are currently sharing your screen';
  text-transform: uppercase;
  background:#cd1a1d;
  letter-spacing: .1em;
  color:#fff;
  display: block;
  height:4.375rem;
  align-self: center;
  width:100vw;
  transform: translateY(-4.375rem);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* top:4.375rem; */
  left:0;
}


.panel-functions input{
  width:50vw;
}

.kp-settings.visible .panel-settings{
  pointer-events: all;
}

.panel-settings{
  /* display: flex; */
  /* grid-area: 4/4/4/4; */
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 2.25rem;
  gap: 1rem; 
  
  /* padding: 0.5rem; */
}

.panel-settings div{
  align-self: center;
  text-align: right;
}
.panel-settings div.text-left{
  text-align: left;
}

.r-guest 
.r-guest .panel-functions{
  /* height:4.375rem; */
  /* grid-template-rows: 4.375rem; */
  height:4.375rem;
}

.kp-panel button,
.g-line button{
  background: transparent;
  border:1px solid #000;
  color:#000;
  font-size:1rem;
  /* border-radius:2rem; */
  text-transform: uppercase;
  height:2.25rem;
  line-height: 2.25rem;
  font-weight: 400;
  /* transition: 300ms cubic-bezier(0.39, 0.575, 0.565, 1) all; */
  cursor: pointer;
}
.kp-panel button.hidden{
  transform: scale(0);
  opacity: 0;
}

span.tag{
  display: block;
  background: transparent;
  /* border:1px dashed #000; */
  color:#000;
  /* padding:0 .75rem; */
  font-size:1rem;
  /* border-radius:2rem; */
  text-transform: uppercase;
  /* height:2.25rem; */
  /* line-height: 2.25rem; */
  font-weight: 400;
  transition: 300ms cubic-bezier(0.39, 0.575, 0.565, 1) all;
  cursor: pointer;
  /* position: absolute; */
  float:right;
  transform: translateX(8rem);
  opacity: 0;
}
.settings-true span.tag{
  opacity: 1;
}

.panel-functions button,
.panel-functions select,
.panel-functions input,
.panel-functions div{
  align-self:center;
  user-select: none;
}

/* .panel-functions button:hover{
  background:#000;
  color:#fff;
} */

.panel-functions>div{
  display: flex;
  gap:1rem;
}

.overlay{
  width:100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  pointer-events: none;
  background:rgba(0,0,0,0);
  /* debugdebugtransition:300ms cubic-bezier(0.33, 1, 0.68, 1) all; */
  transition: 2500ms cubic-bezier(0.075, 0.82, 0.165, 1) all; 
  display: flex;
  justify-content: center;
  opacity: 0;
}
.overlay.overlay-true{
  background:rgba(0,0,0,0.85);
  opacity: 1;
}
.overlay .hidden{
  visibility:hidden;
  pointer-events:none;
}
.overlay.overlay-type-image.overlay-true{
  background:transparent;
}
.settings-true .overlay.overlay-type-image.overlay-true{
  opacity: 0.25;
}

.overlay-type-video{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0);
}
.overlay.overlay-type-video.overlay-true{
  background:transparent;
}
.overlay .video{
  width:80vw;
  height:45vw;
  background:#000;
  align-self: center;
  /* debug transition:300ms cubic-bezier(0.33, 1, 0.68, 1) all; */
  transition: 2500ms cubic-bezier(0.075, 0.82, 0.165, 1) all; 
  transform:scale(0.8);
  opacity: 0;
  /* display: flex; */
  /* justify-content: center; */
  color:#fff;
  /* padding-top:1rem; */
  overflow-y: hidden;
  position: relative;
}
.overlay .video.hidden{
  width:0;
}
.overlay .video>div,
.overlay .video>div>div{
  height: 100%;
}
.overlay .video>div>div{
  pointer-events: none;
}
.overlay .video .ctrls{
  height:50px;
  position:absolute;
  top:0;
}
.overlay .video .ctrls button{
  background:#000;
  color:#fff;
  border:1px solid #fff;
  /* border-radius: 1rem; */
  text-transform: uppercase;
  padding:0 1rem;
  height:2rem;
}
.overlay .video .ctrls button:hover{
  background:#000;
  color:#43FF5F;
  border:1px solid #43FF5F;
  /* border-radius: 1rem; */
  text-transform: uppercase;
  padding:0 1rem;
  height:2rem;
}

.overlay.overlay-true .video{
  transform: scale(1);
  opacity: 1;
}
.overlay.overlay-true button{
  pointer-events: all;
  height:50px;
}
.overlay .video p{
  color:#fff;
  align-self: center;
  margin:0;
}
/* .overlay.overlay-true iframe{
  pointer-events: all;
} */

.overlay li{
  line-height: 1.25em;
}

.avatar{
  display: block;
  align-self: center;
  width:48px;
  height:48px;
  /* margin-bottom: -48px; */
  font-size:1rem;
  /* background:#777; */
  color:#000;
  line-height: 48px;
  border-radius:24px;
  position: relative;
  transform-origin: 24px 24px;
  /* transform: scale(.8) translateY(-48px); */
  /* transition:500ms cubic-bezier(0.33, 1, 0.68, 1) all; */
  transition: all 1000ms cubic-bezier(0.130, 0.460, 0.180, 1.000); /* custom */


  opacity: 0;
  text-align: center;
  margin-left:1rem;
  text-transform: uppercase;
}

.g-n .avatar{
  width: 3vw;
  height: 3vw;
  font-size: 1.25vw;
  line-height: 3vw;
  line-height: 3vw;
  border-radius: 1.5vw;
  transform-origin: 1.5vw 1.5vw;
  margin-top:-1.5rem;
  /* margin-bottom:-1.5rem; */
  top:0.25vw;
  /* transform: scale(.8) translateY(-3vw); */
  /* margin-left: 1rem; */
  transform: scale(0);
  /* transition:500ms cubic-bezier(0.33, 1, 0.68, 1) all; */

}

.avatar.visible{
  transform: scale(1);
  opacity: 1;
}
.avatar.bg-undefined,
.avatar.bg-black,
.avatar.bg-zero{
  background:#000;
  color:#fff;
}

.todo{
  color:rgb(198, 150, 55);
}
.todo:before{
  content:'TODO: ';
  font-size:0.75em;
}

.mice{
  position: absolute;
  top:0;
  left:0;
}

.cur{
  /* backdrop-filter: blur(4px) */
  background: #000;
  width:1em;
  height:1em;
  pointer-events: none;
  
}

li i{
  background:rgba(255, 225, 0, 0.95);
  color:#000;
}

#m-canvas{
  width:100vw;
  height:100vh;
  top:0;
  left:0;
  position: absolute;
  pointer-events: none;
  mix-blend-mode: difference;
  /* transition: 1000ms ease filter; */
}

.playhead{
  width:0;
  border-left:4px solid #cd1a1d;
}

.rct{
  background:red;
  color:white;
  display: inline-block;
}

.overlay-true img,
.settings-true img{
  pointer-events: none;
  opacity: 0;
}

.bgv-true #kp-head{
  background: rgba(255,255,255,0);
  color:#000;
  border-bottom-color:#000;
  /* backdrop-filter: blur(10px); */
}
.bgv-true .panel-functions{
  background: rgba(255,255,255,0);
  /* backdrop-filter: blur(10px); */
}

.t-black .kp-panel .highlight,
.t-norton .kp-panel .highlight,
.t-pastel .kp-panel .highlight
{
  background: #43ff5f;
  color:#000;
  border-color:#000;
  box-shadow: 0 0 1rem 3px rgba(161, 255, 210, 0.5);
  fill :#000;
}
.t-black .kp-panel .highlight svg,
.t-norton .kp-panel .highlight svg,
.t-pastel .kp-panel .highlight svg
{
  fill :#000;
}

img{
  max-width: 100%;
  border-radius: 100%;
}
.img-banana{
  position:absolute;
  left:25vw;
  bottom:-20vh;
}
.img-banana:hover img{
  opacity: 0;
}
.img-banana img{
  position: relative;
  z-index: 1;
  transition: 200ms ease all;
  
}

.not-live{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  height: 100%;
  user-select: none;
}
.log-window .chat-body .message-container.kp-tutorial{
  /* padding-top:4.375rem; */
  /* padding-top: 8.75rem!important; */
  padding-top:1.5rem;
}
.not-live .kp-tutorial>div{
  height:9rem;
  background: #fff;
  box-shadow: .2rem .5rem .5rem .125rem rgba(0, 0, 0, 0.3);
}

.kp-tutorial p{
  font-size:1.6vw;
  margin-bottom:.5em;
}

.kp-interactive{
  font-size:1.6vw;
  width:100%;
  overflow: hidden;
  counter-reset: iline;
  margin-bottom:4rem;
}
.kp-interactive div.line{
  white-space: pre;
  transform: translateX(2vw);
  counter-increment: iline;
  /* transform: translatey(-0.8vw); */
}
.kp-interactive div.line:hover{
  /* font-style: italic; */
  text-decoration: underline;
}
.kp-interactive div{
  white-space: pre;
  transform: translateX(2vw);
}
.kp-interactive div.line:before {
  content: counter(iline, decimal-leading-zero);
  display: inline-block;
  width:4vw;
  text-align: right;
  transform:translateX(-4vw);
  color: #888;
  font-style: normal;
  /* background:orange; */
}

.kp-interactive div.subl{
  transform: translateX(8vw) translateY(-2.25rem);
  height:0;
  color:#cd1a1d;
  pointer-events: none;
}

.not-live span{
  font-size:6vw;
  font-weight: 600;
  align-self: center;
  margin-bottom: 1rem;
  font-family: 'ABCMaxiRoundMono-Regular';
  color:#43FF5F;
}
.not-live .event-note{
  align-self: center;
  user-select: none;
}

#kp-heartbox{
  position: fixed;
  width:100vw;
  height:100vh;
  top:0;
  left:0;
  pointer-events: none;
  background:transparent;
}

.kp-rs{
  position:absolute;
  /* background: url("Images/heart-white.gif"); background-size: cover; */
  display: block;
  width:2.25rem;
  height:2.25rem;
  text-align: center;
  line-height: 2.25rem;
  transition:2000ms ease all;
  transform-origin: 50% 50%;
  animation: scaleup 6.5s cubic-bezier(0.5, 0.025, 0.475, 0.995);
  animation-fill-mode: forwards;
  user-select: none;
  pointer-events: none;
  font-size:2.5rem;
  mix-blend-mode: exclusion;
}
.kp-rs:nth-child(odd){
  animation: scaleup2 7s cubic-bezier(0.4, 0.035, 0.575, 0.95);
  animation-fill-mode: forwards;
}
.kp-rs:nth-child(3n+3){
  animation: scaleup3 6s cubic-bezier(0.55, 0.05, 0.675, 0.98);
  animation-fill-mode: forwards;
}
/* .t-white .kp-rs{ */
  /* background: url("Images/heart-black.gif"); background-size: cover; */
/* } */
/* .kp-rs:before{
  width:0;
  height:0;
  color:#fff;
} */

@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(0);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(0);
  }
}

@keyframes scaleup {
  0% {
    transform: rotate(-10deg) scale(0) translateY(3rem);
  }
  50% {
    transform: rotate(0) scale(1) translateY(1rem);
    opacity: 1;
  }
  100% {
    transform: rotate(10deg) scale(1) translateY(-8rem);
    opacity: 0;
  }
}
@keyframes scaleup2 {
  0% {
    transform: rotate(10deg) scale(0) translateY(3rem);
  }
  50% {
    transform: rotate(0) scale(1) translateY(1rem);
    opacity: 1;
  }
  100% {
    transform: rotate(-10deg) scale(1) translateY(-11rem);
    opacity: 0;
  }
}

@keyframes scaleup3 {
  0% {
    transform: rotate(0deg) scale(0) translateY(3rem);
  }
  50% {
    transform: rotate(5deg) scale(0.8) translateY(1rem);
    opacity: 1;
  }
  100% {
    transform: rotate(-2deg) scale(1) translateY(-9rem);
    opacity: 0;
  }
}


@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn{
  pointer-events: all;
  cursor: pointer;
}

/* Scrollbar */

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    /* scrollbar-color: #8f54a0 #0127f5; */
  }



  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width:0.5rem;
  }

  
.kp-panel input{
  transition: 200ms ease all;
}

/* THEMES */

/* B&W */

/* .t-black .kp-panel{ */
  /* background:#000; */
  /* color:#fff; */
  /* border-color:#fff; */
/* } */
/* .t-black #kp-head{   */
  /* background:transparent; */
  /* color:#000; */
  /* border-bottom:1px solid #000; */
  
/* } */
/* .kp-panel button:hover, */
/* .kp-panel select:hover, */
/* .kp-panel select:active, */
/* .kp-panel input:hover, */
/* .kp-panel input:active{
  background:#fff;
  color:#000;
  border:1px solid #43FF5F;
} */

.t-black .kp-panel button svg{
  fill:#fff;
}
.t-black .kp-panel button:hover svg{
  fill:#000;
}

.t-black button.btn-rd.active svg{
  fill:#000;
}


.t-black .kp-rs{
  color:#000;
}



/* --- state in view --- */

.siv-generic,
.s-pre-event{
  background:#fff;
  color:#000;
  border-bottom-color:#000;
}

/* --- theme in view --- */

.tiv-white{
  background: #fff;
  color:#000;
}
.tiv-photo{
  /* background: #000 url("Images/bg-photo.jpeg"); background-size: cover; background-attachment: fixed; */
  background: #000;
  color:#fff;
}
.tiv-blue{
  background: #0127F5;
  color:#fff;
}
.tiv-grey{
  background: #CDCED2;
  color:#000;
}
.tiv-pink{
  background:#F5588F;
  color:#fff;
}
.tiv-purple{
  background:#6A42C1;
  color:#fff;
}

.tiv-dark{
  background:#111;
  color:#333;
}

/* scrollbars */

.kp-settings::-webkit-scrollbar-thumb { background:#FF558f;}

.tiv-photo *::-webkit-scrollbar-track { background: transparent; }
.tiv-blue *::-webkit-scrollbar-track { background: #0127F5; }
.tiv-pink *::-webkit-scrollbar-track { background: #F5588F; }
.tiv-purple *::-webkit-scrollbar-track { background: #6A42C1; }
.tiv-grey *::-webkit-scrollbar-track { background: #CDCED2; }

.tiv-blue *::-webkit-scrollbar-thumb,
.tiv-pink *::-webkit-scrollbar-thumb,
.tiv-purple *::-webkit-scrollbar-thumb { background-color: #fff; }

.tiv-photo *::-webkit-scrollbar-thumb { background: #fff;}
.tiv-grey *::-webkit-scrollbar-thumb { background: #000;}

/* buttons */

.tiv-photo .kp-panel button:active, .tiv-photo .kp-panel button.active{
  background:#fff;
  color:#000;
}
.tiv-blue .kp-panel button:active, .tiv-blue .kp-panel button.active{
  background: #fff;
  color: #0127F5;
}
.tiv-pink .kp-panel button:active, .tiv-pink .kp-panel button.active{
  background: #fff;
  color: #F5588F;
}
.tiv-purple .kp-panel button:active, .tiv-purple .kp-panel button.active{
  background: #fff;
  color:#6A42C1;
}

.tiv-grey .kp-panel button:active, .tiv-grey .kp-panel button.active{
  background: #fff;
  color:#000;
}

.s-pre-event .kp-panel button:active, .s-pre-event .kp-panel button.active{
  background: #43FF5F;
  color:#000;
}

.kp-panel button.connecting{
  background:#FDEF38;
}

.btn-connect{
  opacity: 0.25;
}

/* speaker color, bg */
.s-pre-event .bg-eins { background: #000; color:#fff;}
.s-pre-event .bg-zwei { background: #666; color:#fff; }
.s-pre-event .bg-drei { background: #0127F5; color:#fff;}
.s-pre-event .bg-vier { background: #FF38B9; color:#fff;}
.s-pre-event .bg-funf { background: #4518A8; color:#fff;}

.tiv-photo .bg-eins { background: #000; color:#fff;}
.tiv-photo .bg-zwei { background: #666; color:#fff; }
.tiv-photo .bg-drei { background: #0127F5; color:#fff;}
.tiv-photo .bg-vier { background: #FF38B9; color:#fff;}
.tiv-photo .bg-funf { background: #FF926A; color:#fff;}

.tiv-blue .bg-eins { background: #fff; color:#0127F5;}
.tiv-blue .bg-zwei { background: #99a9fb; color:#0127F5;}
.tiv-blue .bg-drei { background: #FDEF38; color:#0127F5;}
.tiv-blue .bg-vier { background: #FF38B9; color:#0127F5;}
.tiv-blue .bg-funf { background: #4518A8; color:#0127F5;}

.tiv-pink .bg-eins { background: #fff; color:#F5588F;}
.tiv-pink .bg-zwei { background: #fdd5e3; color:#F5588F;}
.tiv-pink .bg-drei { background: #FDEF38; color:#F5588F;}
.tiv-pink .bg-vier { background: #06FFA5; color:#F5588F;}
.tiv-pink .bg-funf { background: #4518A8; color:#F5588F;}

.tiv-purple .bg-eins { background: #fff; color:#5243a0;}
.tiv-purple .bg-zwei { background: #dad0f0; color:#5243a0;}
.tiv-purple .bg-drei { background: #FDEF38; color:#5243a0;}
.tiv-purple .bg-vier { background: #06FFA5; color:#5243a0;}
.tiv-purple .bg-funf { background: #FF7F50; color:#5243a0;}

.tiv-grey .bg-eins { background: #000; color:#fff; }
.tiv-grey .bg-zwei { background: #666; color:#fff; }
.tiv-grey .bg-drei { background: #0127F5; color:#fff; }
.tiv-grey .bg-vier { background: #FF38B9; color:#fff; }
.tiv-grey .bg-funf { background: #4518A8; color:#fff; }

.tiv-white .bg-eins { background: #000; color:#fff;}
.tiv-white .bg-zwei { background: #666; color:#fff; }
.tiv-white .bg-drei { background: #0127F5; color:#fff;}
.tiv-white .bg-vier { background: #FF38B9; color:#fff;}
.tiv-white .bg-funf { background: #4518A8; color:#fff;}

.tiv-dark .bg-eins { background: #333; color:#000;}
.tiv-dark .bg-zwei { background: #333; color:#000; }
.tiv-dark .bg-drei { background: #333; color:#000;}
.tiv-dark .bg-vier { background: #333; color:#000;}
.tiv-dark .bg-funf { background: #333; color:#000;}

.t-photo .kp-btn-play-big>button{ background:#000;}
.t-white .kp-btn-play-big>button{ background:#fff; color:#000; border-color:#000;outline-color:#000;}
.t-dark .kp-btn-play-big>button{ background:#000;}
.t-grey .kp-btn-play-big>button{ background:#CDCED2; color:#000;border-color:#000; outline-color:#000;}
.t-purple .kp-btn-play-big>button{ background:#6A42C1;}
.t-pink .kp-btn-play-big>button{ background:#FF558f;}
.t-blue .kp-btn-play-big>button{ background:#0127F5;}

/* avatare */

/* speaker color, bg */
.s-pre-event .avatar.bg-eins { background: #000; color:#fff;}
.s-pre-event .avatar.bg-zwei { background: #666; color:#fff; }
.s-pre-event .avatar.bg-drei { background: #0127F5; color:#fff;}
.s-pre-event .avatar.bg-vier { background: #FF38B9; color:#fff;}
.s-pre-event .avatar.bg-funf { background: #4518A8; color:#fff;}

.tiv-photo .avatar.bg-eins { background: #000; color:#fff;}
.tiv-photo .avatar.bg-zwei { background: #666; color:#fff; }
.tiv-photo .avatar.bg-drei { background: #0127F5; color:#fff;}
.tiv-photo .avatar.bg-vier { background: #FF38B9; color:#fff;}
.tiv-photo .avatar.bg-funf { background: #FF38B9; color:#fff;}

.tiv-blue .avatar.bg-eins { background: #fff; color:#0127F5;}
.tiv-blue .avatar.bg-zwei { background: #99a9fb; color:#0127F5;}
.tiv-blue .avatar.bg-drei { background: #FDEF38; color:#0127F5;}
.tiv-blue .avatar.bg-vier { background: #FF38B9; color:#0127F5;}
.tiv-blue .avatar.bg-funf { background: #FF38B9; color:#0127F5;}

.tiv-pink .avatar.bg-eins { background: #fff; color:#F5588F;}
.tiv-pink .avatar.bg-zwei { background: #fdd5e3; color:#F5588F;}
.tiv-pink .avatar.bg-drei { background: #FDEF38; color:#F5588F;}
.tiv-pink .avatar.bg-vier { background: #06FFA5; color:#F5588F;}
.tiv-pink .avatar.bg-funf { background: #4518A8; color:#F5588F;}

.tiv-purple .avatar.bg-eins { background: #fff; color:#5243a0;}
.tiv-purple .avatar.bg-zwei { background: #dad0f0; color:#5243a0;}
.tiv-purple .avatar.bg-drei { background: #FDEF38; color:#5243a0;}
.tiv-purple .avatar.bg-vier { background: #FF7F50; color:#5243a0;}
.tiv-purple .avatar.bg-funf { background: #FF7F50; color:#5243a0;}

.tiv-grey .avatar.bg-eins { background: #000; color:#fff; }
.tiv-grey .avatar.bg-zwei { background: #666; color:#fff; }
.tiv-grey .avatar.bg-drei { background: #0127F5; color:#fff; }
.tiv-grey .avatar.bg-vier { background: #FF38B9; color:#fff; }
.tiv-grey .avatar.bg-funf { background: #4518A8; color:#fff; }

.tiv-white .avatar.bg-eins { background: #000; color:#fff;}
.tiv-white .avatar.bg-zwei { background: #666; color:#fff; }
.tiv-white .avatar.bg-drei { background: #0127F5; color:#fff;}
.tiv-white .avatar.bg-vier { background: #FF38B9; color:#fff;}
.tiv-white .avatar.bg-funf { background: #4518A8; color:#fff;}

.tiv-white .avatar.bg-eins { background: #333; color:#000;}
.tiv-white .avatar.bg-zwei { background: #333; color:#000; }
.tiv-white .avatar.bg-drei { background: #333; color:#000;}
.tiv-white .avatar.bg-vier { background: #333; color:#000;}
.tiv-white .avatar.bg-funf { background: #333; color:#000;}
.tiv-white .avatar {background:#000; color:#fff;}



/* speaker color, fg */

.s-pre-event .c-eins { color: #000;    }
.s-pre-event .c-zwei { color: #666;    }
.s-pre-event .c-drei { color: #0127F5; }
.s-pre-event .c-vier { color: #FF38B9; }
.s-pre-event .c-funf { color: #4518A8; }

.tiv-photo .c-eins { color: #fff; }
.tiv-photo .c-zwei { color: #FF7F50; }
.tiv-photo .c-drei { color: #FDEF38; }
.tiv-photo .c-vier { color: #06FFA5; }
.tiv-photo .c-funf { color: #FF38B9; }
.tiv-photo .kp-handle:before { color: #fff;}

.tiv-blue .c-eins { color: #fff; }
.tiv-blue .c-zwei { color: #99a9fb; }
.tiv-blue .c-drei { color: #FDEF38; }
.tiv-blue .c-vier { color: #FF38B9; }
.tiv-blue .c-funf { color: #FF7F50; }

.tiv-pink .c-eins { color: #fff; }
.tiv-pink .c-zwei { color: #fdd5e3; }
.tiv-pink .c-drei { color: #FDEF38; }
.tiv-pink .c-vier { color: #06FFA5; }
.tiv-pink .c-funf { color: #4518A8; }

.tiv-purple .c-eins { color: #fff; }
.tiv-purple .c-zwei { color: #dad0f0; }
.tiv-purple .c-drei { color: #FDEF38; }
.tiv-purple .c-vier { color: #80FFE8; }
.tiv-purple .c-funf { color: #FF7F50; }

.tiv-grey .c-eins { color: #000; }
.tiv-grey .c-zwei { color: #666; }
.tiv-grey .c-drei { color: #0127F5; }
.tiv-grey .c-vier { color: #FF38B9; }
.tiv-grey .c-funf { color: #4518A8; }
.t-grey .kp-handle:before { color: #000;}

.tiv-white .c-eins { color: #000; }
.tiv-white .c-zwei { color: #666; }
.tiv-white .c-drei { color: #0127F5; }
.tiv-white .c-vier { color: #FF38B9; }
.tiv-white .c-funf { color: #4518A8; }

.tiv-dark .c-eins { color: #333; }
.tiv-dark .c-zwei { color: #333; }
.tiv-dark .c-drei { color: #333; }
.tiv-dark .c-vier { color: #fff; }
.tiv-dark .c-funf { color: #fff; }

/* recasts */
.tiv-blue .c-blue{ color:#fff; }
.tiv-pink .c-pink{ color:#fff; }
.tiv-pink .c-red{ color:#fff; }
.tiv-purple .c-purple{ color:#fff; }

/* avatare */

.tiv-photo .avatar{ background:#fff; color:#000; }
.tiv-blue .avatar{background:#fff; color:#0127F5;}
.tiv-pink .avatar{background:#fff; color:#F5588F;}
.tiv-purple .avatar{background:#fff; color:#6A42C1;}
.s-pre-event .avatar { color:#fff; }
/* .tiv- .avatar { background:#000; color:#fff;} */
.tiv-dark .avatar {background: #333; color:#000;}

.avatar.bg-blue{
  background:#0127F5;
}

/* line numbers */


.tiv-blue .g-line:before,
.tiv-pink .g-line:before,
.tiv-purple .g-line:before{
  color:rgba(255,255,255,0.5);
}
.tiv-photo .g-line:before,
.tiv-grey .g-line:before,
.tiv-white .g-line:before{
  color:rgba(0,0,0,0.5);
}

.tiv-dark .g-line:before{
  color: #333;
}

.kp-colgrid{
  display: grid;
  gap:.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height:2rem;
}

/* buttons */

.kp-panel button:hover,
.kp-panel select:hover,
.kp-panel input:hover{
  outline-width: 2px;
  outline-style: solid;
}

.tiv-blue .kp-panel button, .tiv-blue  .kp-panel select, .tiv-blue .kp-panel input,
.tiv-pink .kp-panel button, .tiv-pink  .kp-panel select, .tiv-pink .kp-panel input,
.tiv-purple .kp-panel button, .tiv-purple  .kp-panel select, .tiv-purple .kp-panel input,
.tiv-photo .kp-panel button, .tiv-photo  .kp-panel select, .tiv-photo .kp-panel input{
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  color:#fff;
  outline-color: #fff;
}
.tiv-blue .kp-panel input::placeholder,
.tiv-pink .kp-panel input::placeholder,
.tiv-purple .kp-panel input::placeholder,
.tiv-photo .kp-panel input::placeholder{
  color:rgba(255,255,255,0.5);
}

.tiv-blue .kp-panel input:focus::placeholder,
.tiv-pink .kp-panel input:focus::placeholder,
.tiv-purple .kp-panel input:focus::placeholder,
.tiv-photo .kp-panel input:focus::placeholder{
  color:rgba(0,0,0,1);
}
  .tiv-blue .kp-panel input:hover::placeholder,
  .tiv-pink .kp-panel input:hover::placeholder,
.tiv-purple .kp-panel input:hover::placeholder,
 .tiv-photo .kp-panel input:hover::placeholder{
  color:rgba(0,0,0,1);
}

.kp-panel input:active,
.kp-panel select:active,
.kp-panel input:focus{
  background: #fff !important;
  color:#000 !important;
  border-color:#000;
}

.tiv-grey .kp-panel button, .tiv-grey  .kp-panel select, .tiv-grey .kp-panel input,
.tiv-white .kp-panel button, .tiv-white  .kp-panel select, .tiv-white .kp-panel input{
  border-color: #000;
  color:#000;
  outline-color: #000;
}

.tiv-dark .kp-panel button, .tiv-dark  .kp-panel select, .tiv-dark .kp-panel input{
  border-color:#43FF5F;
  color:#43FF5F;
  outline-color: #43FF5F;
}
.tiv-dark .kp-panel button#btn-share{
  border-color:#43FF5F;
  color:#43FF5F;
}
.tiv-dark .kp-panel button#btn-unshare{
  background:#cd1a1d;
  color:#fff;
}
.tiv-dark .kp-panel button.active{
  background:#333;
  color:#000;
  outline-color: #333;
}


.bgv-true  .g-line::before{
  color:rgba(0,0,0,0.5);
  mix-blend-mode: exclusion;
}
/* .bgv-true .g-line{ */
  /* color:#fff; */
/* } */

/* NON THEME IN VIEW REDECLARATION */

/* --- theme in view --- */

.t-white, .s-post-event.tiv-white{
  background: #fff;
  color:#000;
}
.t-photo, .s-post-event.tiv-photo{
  background: #000; /*url("Images/bg-photo.jpeg"); background-size: cover; background-attachment: fixed;*/
  /* background: #0127F5; */
  color:#fff;
}
.t-blue, .s-post-event.tiv-blue{
  background: #0127F5;
  color:#fff;
}
.t-grey, .s-post-event.tiv-grey{
  background: #CDCED2;
  color:#000;
}
.t-pink, .s-post-event.tiv-pink{
  background:#F5588F;
  color:#fff;
}
.t-purple, .s-post-event.tiv-purple{
  background:#6A42C1;
  color:#fff;
}

.t-dark, .s-post-event.tiv-dark{
  background:#111;
  color:#333;
}

.t-photo *::-webkit-scrollbar-track { background: transparent; }
.t-blue *::-webkit-scrollbar-track { background: #0127F5; }
.t-pink *::-webkit-scrollbar-track { background: #F5588F; }
.t-purple *::-webkit-scrollbar-track { background: #6A42C1; }
.t-grey *::-webkit-scrollbar-track { background: #CDCED2; }

.t-blue *::-webkit-scrollbar-thumb,
.t-pink *::-webkit-scrollbar-thumb,
.t-purple *::-webkit-scrollbar-thumb { background-color: #fff; }

.t-photo *::-webkit-scrollbar-thumb { background: #fff;}
.t-grey *::-webkit-scrollbar-thumb { background: #000;}

/* buttons */

.t-photo .kp-panel button:active, .t-photo .kp-panel button.active,
.s-post-event.tiv-photo .kp-panel button:active, .s-post-event.tiv-photo .kp-panel button.active{
  background:#fff;
  color:#000;
}
.t-blue .kp-panel button:active, .t-blue .kp-panel button.active,
.s-post-event.tiv-blue .kp-panel button:active, .s-post-event.tiv-blue .kp-panel button.active{
  background: #fff;
  color: #0127F5;
}
.t-pink .kp-panel button:active, .t-pink .kp-panel button.active,
.s-post-event.tiv-photo .kp-panel button:active, .s-post-event.tiv-photo .kp-panel button.active{
  background: #fff;
  color: #F5588F;
}
.t-purple .kp-panel button:active, .t-purple .kp-panel button.active,
.s-post-event.tiv-purple .kp-panel button:active, .s-post-event.tiv-purple .kp-panel button.active{
  background: #fff;
  color:#6A42C1;
}

.t-grey .kp-panel button:active, .t-grey .kp-panel button.active,
.s-post-event.tiv-grey .kp-panel button:active, .s-post-event.tiv-grey .kp-panel button.active{
  background: #fff;
  color:#000;
}

.t-grey .kp-panel button, .t-grey .kp-panel select, .t-grey .kp-panel input,
.t-white .kp-panel button, .t-white .kp-panel select, .t-white .kp-panel input{
  border-color: #000;
  color:#000;
  outline-color: #000;
}

.t-dark .kp-panel button, .t-dark .kp-panel select, .t-dark .kp-panel input{
  border-color:#43FF5F;
  color:#43FF5F;
  outline-color: #43FF5F;
}

/* --- */

.s-post-event.tiv-blue .kp-panel button,
.s-post-event.tiv-pink .kp-panel button,
.s-post-event.tiv-purple .kp-panel button{
  border-color:#fff;
  color:#fff;
  outline-color:#fff;
}
.s-post-event.tiv-white .kp-panel button,
.s-post-event.tiv-grey .kp-panel button{
  border-color:#000;
  color:#000;
  outline-color:#000;
}

.s-post-event.tiv-dark .kp-panel button{
  border-color:#43ff5f;
  color:#43ff5f;
  outline-color:#43ff5f;
}



/* --- */

.s-pre-event .bg-eins { background: #000; color:#fff;}
.s-pre-event .bg-zwei { background: #666; color:#fff; }
.s-pre-event .bg-drei { background: #0127F5; color:#fff;}
.s-pre-event .bg-vier { background: #FF38B9; color:#fff;}
.s-pre-event .bg-funf { background: #4518A8; color:#fff;}

.t-photo .bg-eins { background: #000; color:#fff;}
.t-photo .bg-zwei { background: #666; color:#fff; }
.t-photo .bg-drei { background: #0127F5; color:#fff;}
.t-photo .bg-vier { background: #FF38B9; color:#fff;}
.t-photo .bg-funf { background: #FF926A; color:#fff;}

.t-blue .bg-eins { background: #fff; color:#0127F5;}
.t-blue .bg-zwei { background: #99a9fb; color:#0127F5;}
.t-blue .bg-drei { background: #FDEF38; color:#0127F5;}
.t-blue .bg-vier { background: #FF38B9; color:#0127F5;}
.t-blue .bg-funf { background: #4518A8; color:#0127F5;}

.t-pink .bg-eins { background: #fff; color:#F5588F;}
.t-pink .bg-zwei { background: #fdd5e3; color:#F5588F;}
.t-pink .bg-drei { background: #FDEF38; color:#F5588F;}
.t-pink .bg-vier { background: #06FFA5; color:#F5588F;}
.t-pink .bg-funf { background: #4518A8; color:#F5588F;}

.t-purple .bg-eins { background: #fff; color:#5243a0;}
.t-purple .bg-zwei { background: #dad0f0; color:#5243a0;}
.t-purple .bg-drei { background: #FDEF38; color:#5243a0;}
.t-purple .bg-vier { background: #06FFA5; color:#5243a0;}
.t-purple .bg-funf { background: #FF7F50; color:#5243a0;}

.t-grey .bg-eins { background: #000; color:#fff; }
.t-grey .bg-zwei { background: #666; color:#fff; }
.t-grey .bg-drei { background: #0127F5; color:#fff; }
.t-grey .bg-vier { background: #FF38B9; color:#fff; }
.t-grey .bg-funf { background: #4518A8; color:#fff; }

.t-white .bg-eins { background: #000; color:#fff;}
.t-white .bg-zwei { background: #666; color:#fff; }
.t-white .bg-drei { background: #0127F5; color:#fff;}
.t-white .bg-vier { background: #FF38B9; color:#fff;}
.t-white .bg-funf { background: #4518A8; color:#fff;}

.t-dark .bg-eins { background: #333; color:#000;}
.t-dark .bg-zwei { background: #333; color:#000; }
.t-dark .bg-drei { background: #333; color:#000;}
.t-dark .bg-vier { background: #333; color:#000;}
.t-dark .bg-funf { background: #333; color:#000;}

.t-white .kp-rs svg polygon { fill: #F15148;}
.t-dark .kp-rs svg polygon { fill: #43FF5F;}
.t-grey .kp-rs svg polygon { fill: #FF38B9;}
.t-blue .kp-rs svg polygon { fill: #FDEF38;}
.t-pink .kp-rs svg polygon { fill: #5DD9C1;}
.t-purple .kp-rs svg polygon { fill: #F15148;}
.t-photo .kp-rs svg polygon { fill: #fff;}

/* avatare */

/* speaker color, bg */
.s-pre-event .avatar.bg-eins { background: #000; color:#fff;}
.s-pre-event .avatar.bg-zwei { background: #666; color:#fff; }
.s-pre-event .avatar.bg-drei { background: #0127F5; color:#fff;}
.s-pre-event .avatar.bg-vier { background: #FF38B9; color:#fff;}
.s-pre-event .avatar.bg-funf { background: #4518A8; color:#fff;}

.t-photo .avatar.bg-eins { background: #000; color:#fff;}
.t-photo .avatar.bg-zwei { background: #666; color:#fff; }
.t-photo .avatar.bg-drei { background: #0127F5; color:#fff;}
.t-photo .avatar.bg-vier { background: #FF38B9; color:#fff;}
.t-photo .avatar.bg-funf { background: #FF38B9; color:#fff;}

.t-blue .avatar.bg-eins { background: #fff; color:#0127F5;}
.t-blue .avatar.bg-zwei { background: #99a9fb; color:#0127F5;}
.t-blue .avatar.bg-drei { background: #FDEF38; color:#0127F5;}
.t-blue .avatar.bg-vier { background: #FF38B9; color:#0127F5;}
.t-blue .avatar.bg-funf { background: #FF38B9; color:#0127F5;}

.t-pink .avatar.bg-eins { background: #fff; color:#F5588F;}
.t-pink .avatar.bg-zwei { background: #fdd5e3; color:#F5588F;}
.t-pink .avatar.bg-drei { background: #FDEF38; color:#F5588F;}
.t-pink .avatar.bg-vier { background: #06FFA5; color:#F5588F;}
.t-pink .avatar.bg-funf { background: #4518A8; color:#F5588F;}

.t-purple .avatar.bg-eins { background: #fff; color:#5243a0;}
.t-purple .avatar.bg-zwei { background: #dad0f0; color:#5243a0;}
.t-purple .avatar.bg-drei { background: #FDEF38; color:#5243a0;}
.t-purple .avatar.bg-vier { background: #FF7F50; color:#5243a0;}
.t-purple .avatar.bg-funf { background: #FF7F50; color:#5243a0;}

.t-grey .avatar.bg-eins { background: #000; color:#fff; }
.t-grey .avatar.bg-zwei { background: #666; color:#fff; }
.t-grey .avatar.bg-drei { background: #0127F5; color:#fff; }
.t-grey .avatar.bg-vier { background: #FF38B9; color:#fff; }
.t-grey .avatar.bg-funf { background: #4518A8; color:#fff; }

.t-white .avatar.bg-eins { background: #000; color:#fff;}
.t-white .avatar.bg-zwei { background: #666; color:#fff; }
.t-white .avatar.bg-drei { background: #0127F5; color:#fff;}
.t-white .avatar.bg-vier { background: #FF38B9; color:#fff;}
.t-white .avatar.bg-funf { background: #4518A8; color:#fff;}

.t-dark .avatar.bg-eins { background: #333; color:#000;}
.t-dark .avatar.bg-zwei { background: #333; color:#000; }
.t-dark .avatar.bg-drei { background: #333; color:#000;}
.t-dark .avatar.bg-vier { background: #333; color:#000;}
.t-dark .avatar.bg-funf { background: #333; color:#000;}
/* .t-white .avatar {background:#000; color:#fff;} */

.s-post-event.tiv-blue .avatar{ background:#fff; color:#0127F5;}
.s-post-event.tiv-pink .avatar{ background:#fff; color:#F5588F;}
.s-post-event.tiv-purple .avatar{ background:#fff; color:#5243a0;}
.s-post-event.tiv-dark .avatar{ background:#333; color:#000;}
.s-post-event.tiv-photo .avatar{ background:#000; color:#FFF;}
.s-post-event.tiv-grey .avatar{ background:#000; color:#CDCED2;}
.s-post-event.tiv-white .avatar{ background:#000; color:#FFF;}

/* speaker color, fg */

.t-photo .c-eins, .s-post-event.tiv-photo .c-eins { color: #fff; }
.t-photo .c-zwei, .s-post-event.tiv-photo .c-zwei { color: #FF7F50; }
.t-photo .c-drei, .s-post-event.tiv-photo .c-drei { color: #FDEF38; }
.t-photo .c-vier, .s-post-event.tiv-photo .c-vier { color: #FF558f; }
.t-photo .c-funf, .s-post-event.tiv-photo .c-funf { color: #FF38B9; }

.t-blue .c-eins, .s-post-event.tiv-blue .c-eins { color: #fff; }
.t-blue .c-zwei, .s-post-event.tiv-blue .c-zwei { color: #99a9fb; }
.t-blue .c-drei, .s-post-event.tiv-blue .c-drei { color: #FDEF38; }
.t-blue .c-vier, .s-post-event.tiv-blue .c-vier { color: #FF38B9; }
.t-blue .c-funf, .s-post-event.tiv-blue .c-funf { color: #FF7F50; }

.t-pink .c-eins, .s-post-event.tiv-pink .c-eins { color: #fff; }
.t-pink .c-zwei, .s-post-event.tiv-pink .c-zwei { color: #fdd5e3; }
.t-pink .c-drei, .s-post-event.tiv-pink .c-drei { color: #FDEF38; }
.t-pink .c-vier, .s-post-event.tiv-pink .c-vier { color: #06FFA5; }
.t-pink .c-funf, .s-post-event.tiv-pink .c-funf { color: #4518A8; }

.t-purple .c-eins, .s-post-event.tiv-purple .c-eins { color: #fff; }
.t-purple .c-zwei, .s-post-event.tiv-purple .c-zwei { color: #dad0f0; }
.t-purple .c-drei, .s-post-event.tiv-purple .c-drei { color: #FDEF38; }
.t-purple .c-vier, .s-post-event.tiv-purple .c-vier { color: #80FFE8; }
.t-purple .c-funf, .s-post-event.tiv-purple .c-funf { color: #FF7F50; }

.t-grey .c-eins, .s-post-event.tiv-grey .c-eins { color: #000; }
.t-grey .c-zwei, .s-post-event.tiv-grey .c-zwei { color: #666; }
.t-grey .c-drei, .s-post-event.tiv-grey .c-drei { color: #0127F5; }
.t-grey .c-vier, .s-post-event.tiv-grey .c-vier { color: #FF38B9; }
.t-grey .c-funf, .s-post-event.tiv-grey .c-funf { color: #4518A8; }

.t-white .c-eins, .s-post-event.tiv-white .c-eins { color: #000; }
.t-white .c-zwei, .s-post-event.tiv-white .c-zwei { color: #666; }
.t-white .c-drei, .s-post-event.tiv-white .c-drei { color: #0127F5; }
.t-white .c-vier, .s-post-event.tiv-white .c-vier { color: #FF38B9; }
.t-white .c-funf, .s-post-event.tiv-white .c-funf { color: #4518A8; }

.t-dark .c-eins, .s-post-event.tiv-dark .c-eins { color: #fff; }
.t-dark .c-zwei, .s-post-event.tiv-dark .c-zwei { color: #fff; }
.t-dark .c-drei, .s-post-event.tiv-dark .c-drei { color: #fff; }
.t-dark .c-vier, .s-post-event.tiv-dark .c-vier { color: #fff; }
.t-dark .c-funf, .s-post-event.tiv-dark .c-funf { color: #fff; }

/* recasts */
.t-blue .c-blue{ color:#fff; }
.t-pink .c-pink{ color:#fff; }
.t-pink .c-red{ color:#fff; }
.t-purple .c-purple{ color:#fff; }

/* avatare */

.t-photo .avatar{ background:#fff; color:#000; }
.t-blue .avatar{background:#fff; color:#0127F5;}
.t-pink .avatar{background:#fff; color:#F5588F;}
.t-purple .avatar{background:#fff; color:#6A42C1;}
.t-dark .avatar {background: #333; color:#000;}

/* line numbers */

.t-blue .g-line:before,
.t-pink .g-line:before,
.t-purple .g-line:before,
.s-post-event.tiv-blue .g-line:before,
.s-post-event.tiv-pink .g-line:before,
.s-post-event.tiv-purple .g-line:before{
  color:rgba(255,255,255,0.5);
}

.t-grey .g-line:before,
.t-white .g-line:before,
.s-post-event.tiv-grey .g-line:before,
.s-post-event.tiv-white .g-line:before{
  color:rgba(0,0,0,0.5);
}

.t-dark .g-line:before,
.s-post-event.tiv-dark .g-line:before{
  color: #333;
}

/* buttons */

.t-blue .kp-panel button, .t-blue  .kp-panel select, .t-blue .kp-panel input,
.t-pink .kp-panel button, .t-pink  .kp-panel select, .t-pink .kp-panel input,
.t-purple .kp-panel button, .t-purple  .kp-panel select, .t-purple .kp-panel input,
.t-photo .kp-panel button, .t-photo  .kp-panel select, .t-photo .kp-panel input{
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  color:#fff;
  outline-color: #fff;
}
.t-blue .kp-panel input::placeholder,
.t-pink .kp-panel input::placeholder,
.t-purple .kp-panel input::placeholder,
.t-photo .kp-panel input::placeholder{
  color:rgba(255,255,255,0.5);
}

.t-blue .kp-panel input:focus::placeholder,
.t-pink .kp-panel input:focus::placeholder,
.t-purple .kp-panel input:focus::placeholder,
.t-photo .kp-panel input:focus::placeholder{
  color:rgba(0,0,0,1);
}
  .t-blue .kp-panel input:hover::placeholder,
  .t-pink .kp-panel input:hover::placeholder,
.t-purple .kp-panel input:hover::placeholder,
 .t-photo .kp-panel input:hover::placeholder{
  color:rgba(0,0,0,1);
}


.t-dark .kp-panel button#btn-share{
  border-color:#43FF5F;
  color:#43FF5F;
}
.t-dark .kp-panel button#btn-unshare{
  background:#cd1a1d;
  color:#fff;
}
.t-dark .kp-panel button.active{
  background:#333;
  color:#000;
  outline-color: #333;
}



/* siv override: pre- and post-event */
.s-pre-event{ background:#fff;}
.s-pre-event .g-subline{ color:#43ff5f; }
.t-white .g-subline { color: #43FF5F;}
.t-grey .g-subline { color: #FF558f;}
.t-blue .g-subline { color: #FDEF38;}
.t-pink .g-subline { color: #fdd5e3;}
.t-purple .g-subline { color: #dad0f0;}
.t-dark .g-subline { color: #43FF5F;}
.t-photo .g-subline { color: rgba(255,255,255,0.25);}
/* .s-pre-event .kp-headline h1, .s-pre-event .kp-countdown { color:#0127F5; }  */
.s-pre-event .kp-panel{
  background:#CDCED2; color:#000;
}


.s-post-event{ background:#fff; }


/* --------------------- */

.bg-yt-container{
  width: 100vw;
  height:100vh;
  position: absolute;
  top:0;
  left:0;
}

.bg-yt{
  width: 100vw;
  height:100vh;
  position: relative;
  overflow: hidden;
  /* height: 100%;/ */
  opacity: 0;
  transition: 1000ms opacity linear;
  /* height:500px; */
}
.bg-yt.playing{
   opacity: 1; 
}

.bg-yt video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  pointer-events: none;
  object-fit: cover;
}

@media (min-aspect-ratio: 16/9) {
  .bg-yt video {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .bg-yt video {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}


.bg-yt.playing{
  opacity: 1;
}
.bg-yt>div{
  height:100vh;
} 

.kp-endref{
  height:40vh;
  opacity: 0;
}

@keyframes animatein {
  0% { opacity: 0; transform: scale(0.9);}
  100% { opacity: 1; transform: scale(1);}
}

@keyframes fadeeasy {
  0% { opacity: 1;}
  100% { opacity: 0;}
}

@keyframes fadeout {
  0% { opacity: 1;}
  100% { opacity: 0; filter:blur(20px)}
}

.kp-headline{
  width: 100vw;
  height:100vh;
  position:fixed;
  top:0;
  pointer-events: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.kp-headline h1,
.kp-countdown{
  font-size:11.5vw;
  color:#43FF5F;
  font-family: "ABCMaxiRoundMono-Regular", monospace;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 1em;
  /* position: relative;
  z-index: 1; */
}
.kp-headline h1{
  margin: 0 0 0 0;
  font-size: 10vw;
  width: 98vw;
  margin-bottom:1.5rem;
  line-height:1.1em;
  /* word-break: break-all; */
}
.kp-headline h1.dialogue-1,
.kp-headline h1.dialogue-2{
  font-size: 20vw;
  margin-bottom:1.5rem;
  line-height:1.1em;
  /* word-break: break-all; */
}
.kp-headline h2{
  font-family: "ABCMaxiRoundMono-Regular", monospace;
  /* word-break: break-all; */
  /* font-size:3.5vw; */
  font-size:5vw;
  margin:0;
  text-align: center;
  color:#43FF5F;
  letter-spacing: 0.225em;
  max-width: 80vw;

}
.kp-headline h1.fadeout,
.kp-headline h2.fadeout{
  animation: fadeout 10s linear 5s 1 normal forwards;
}

.kp-inline-image{
  align-self: center;
  opacity: 0;
  transform: perspective(75em) translateY(-.5rem) rotateX(15deg) scale(0.9);
  transition: 2500ms cubic-bezier(0.075, 0.82, 0.165, 1) all; 
  background:#000;
  height:50vh;
  overflow: hidden;
}
.r-admin .kp-inline-image.visible,
.r-moderator .kp-inline-image.visible,
.r-speaker .kp-inline-image.visible{
  transform: translateX(0vw);
}

.kp-inline-image.visible{
  opacity: 1;
  transform:scale(1);
}
.kp-inline-image.visible:hover{
  opacity: 0.2;
}

.kp-inline-image img{
  opacity: 1;
  border-radius: 0;
  height:100%;
  /* width:100%; */
}

.overlay-true .kp-inline-image img{
  opacity: 1;
  border-radius: 0;
  max-height:100%;
  /* max-width:100%; */
}

#kp-neighbor{
  position: absolute;
  color:#43FF5F;
  width:200px;
  text-align: center;
  margin-left:-100px;
  height:20px;
  margin-top:-10px;
  pointer-events: none;
  font-size:1rem;
}

.kp-modal-container,
.kp-schedule-container{
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height:100vh;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.kp-modal-container{
  background: rgba(0,0,255,0.0);
  backdrop-filter: blur(0px);
  transition:1000ms ease all;
  pointer-events: all;
}

.kp-modal-container.visible{
  background: rgba(0,0,255,0.5);
  backdrop-filter: blur(10px);
  opacity: 1;
  /* mix-blend-mode: screen; */
}
.kp-modal-container.visible.modal-2{
  background:#FF38B9;
  mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  
}

.kp-modal-container .kp-modal{
  transform: perspective(75em) rotateX(22deg) scale(0.95);
  opacity: 0;
  transition: 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) all; 
}

.kp-modal-container.visible .kp-modal{
  transform: scale(1);
  opacity: 1;
}

.kp-schedule.visible{
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.kp-schedule{
  width: fit-content;
  max-width: 50vw;
  /* height:fit-content; */
  max-height: 80vh;
  overflow-y: scroll;
  box-sizing: border-box;
  background:rgba(0,0,0,0.8);
  /* backdrop-filter: blur(10px); */
  color:#fff;
  align-self: center;
  /* align-self: center; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* pointer-events: all; */
  opacity: 0;
  transform: perspective(75em) rotateX(8deg) scale(0.9);
  transition: 2500ms cubic-bezier(0.075, 0.82, 0.165, 1) all; 

  /* animation: animatein 0s cubic-bezier(0.165, 0.84, 0.44, 1) 1s 1 normal forwards; */
}
.kp-schedule-body{
  height: 100%;
  padding:2rem 0rem;  
}

.kp-schedule tr td{
  transition: 500ms ease all;
}
.kp-schedule tr.active td{
  background:#43FF5F;
  color:#000;

}

.kp-modal{
  /* width:50vw; */
  width: fit-content;
  max-width: 70vw;
  height:fit-content;
  background:#000;
  color:#fff;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kp-modal .kp-modal-head{
  border-bottom:1px solid #fff;
  padding:0.5rem 2rem;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}
.kp-modal .kp-modal-body{
  padding:2rem 2rem;
}
.kp-modal .kp-modal-head h1{
  text-transform: uppercase;
  font-size:1rem;
  width:100%;
}
.kp-modal .btn-group{
  display: flex;
  gap:1rem;
  justify-content: center;
}
.kp-modal button{
  pointer-events: all;
  border:1px solid #000;
  color:#000;
  font-size:1rem;
  text-transform: uppercase;
  height:2.25rem;
  line-height: 2.25rem;
  font-weight: 400;
  padding:0 2rem;
  cursor: pointer;
}
#kp-window button.btn-modal:hover{
  background:#fff;
  color:#000;
}
#kp-window button.btn-modal{
  background:#000;
  color:#fff;
  border:1px solid #fff;
}


/* audio */


.kp-progress{
  position: relative;
}

.kp-progress-time{
  width:6rem;
  overflow: hidden;
}

.kp-chapters{
  position: relative;
  background:#333;
  color:#fff;
  display: flex;
  width:75vw;
  height:1.5rem;
  border-radius:1rem;
  overflow: hidden;
  /* box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5); */
  outline: 1px solid #fff;
  padding:0;
  gap:0;
  margin:0;
  border:0;
  cursor: pointer;
  /* padding:0 1rem; */
}
.kp-chapters>div{
  transition:200ms ease all;
  border:0;
  border-left:1px dotted #fff;
  box-sizing: border-box;
  margin:0;
  outline:0;
  
}
.kp-chapters>div:first-child{
  border-left:0;
}
.kp-chapters>div:hover{
  box-shadow: inset 0 0 1rem 0 rgba(255, 255, 255, 0.5);
  /* mix-blend-mode:exclusion; */
  /* box-shadow: inset 0 0 1rem 0 rgba(255,255,255,0.5) ; */
}

.kp-play{
  width:6.2rem;
  /* background: green; */
  /* overflow-x: hidden; */
}

.kp-handle{
  width:1.25rem;
  height:1.25rem;
  background:red;
  border-radius: 0.75rem;
  position:absolute;
  /* left:0.25rem; */
  background:#fff;
  transform: translateX(-0.625em);
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1) all;
  cursor:grab;
  pointer-events: all;
  mix-blend-mode: exclusion;
}
.kp-handle::before{
  content:attr(data-title);
  display:inline-block;
  font-size:.75rem;
  text-align: center;
  padding:0.125rem;
  position: absolute;
  bottom:1.5rem;
  right:-0.5rem;
  width:3rem;
  opacity: 0;
  transition: 300ms ease all;
}
.kp-handle:hover::before{
  content:attr(data-title);
  display:inline-block;
  font-size:.75rem;
  text-align: center;
  padding:0.125rem;
  position: absolute;
  bottom:1.5rem;
  right:-0.5rem;
  width:3rem;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  
  body, .App, #kp-window, #kp-gui, .overlay, #m-canvas, .bg-yt-container, .bg-yt, .bg-yt iframe, .bg-yt>div, .kp-headline, .kp-modal-container,
  .kp-schedule-container{
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  html{
    min-height: -webkit-fill-available;
  }

  #kp-head h1{
    font-size:1rem;
  }

  .panel-functions{
    display: none;
  }

  .kp-schedule{
    max-width: 95vw;
    font-size:0.6rem;
  }

}

@media only screen and (min-width: 601px) and (max-width: 900px) {

    .kp-schedule{
      max-width:80vw;
    }

}
